import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "inputWrapper",
    "toggleable",
  ];

  connect() {
    this.toggle();
  }

  toggle() {
    const selected = this.inputWrapperTarget.querySelector("input:checked");
    const showValue = this.inputWrapperTarget.dataset.showValue;
    const hideValue = this.inputWrapperTarget.dataset.hideValue;

    if (
      (showValue && selected && selected.value == showValue) ||
      (hideValue && (!selected || selected.value != hideValue))
    ) {
      this.toggleableTarget.classList.remove("hidden");
      this.toggleableTarget.querySelectorAll("input").forEach(input => {
        input.disabled = false;
      });
    } else {
      this.toggleableTarget.classList.add("hidden");
      this.toggleableTarget.querySelectorAll("input").forEach(input => {
        input.disabled = true;
      });
    }
  }
}

