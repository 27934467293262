import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.initialize();
    this.addListeners();
  }

  disconnect() {
    this.removeListeners();
  }

  initialize() {
    this.slots = new Set(this.element.dataset.slots || "_");
    this.pattern = this.element.placeholder;
    this.accept = new RegExp(this.element.dataset.accept || "\\d", "g");
    this.prev = (j => Array.from(this.pattern, (char, i) => {
      return this.slots.has(char) ? j = i + 1 : j;
    }))(0);
    this.first = [...this.pattern].findIndex(c => this.slots.has(c));
    this.back = false;
  }

  addListeners() {
    this.element.addEventListener("keydown", (e) => {
      return this.back = e.key === "Backspace";
    });
    this.element.addEventListener("input", this.format.bind(this));
    this.element.addEventListener("focus", this.format.bind(this));
    this.element.addEventListener("blur", () => {
      return this.element.value === this.pattern && (this.element.value = "");
    });
  }

  removeListeners() {
    this.element.removeEventListener("keydown");
    this.element.removeEventListener("input");
    this.element.removeEventListener("focus");
    this.element.removeEventListener("blur");
  }

  clean(input) {
    input = input.match(this.accept) || [];
    return Array.from(
      this.pattern,
      char => input[0] === char || this.slots.has(char)
        ? input.shift() || char
        : char
    );
  }

  format(event) {
    const [i, j] = [
      event.target.selectionStart,
      event.target.selectionEnd
    ].map(i => {
      i = this.clean(
        event.target.value.slice(0, i)
      ).findIndex(c => this.slots.has(c));

      return i < 0
        ? this.prev[this.prev.length - 1]
        : this.back ? this.prev[i - 1] || this.first : i;
    });
    event.target.value = this.clean(event.target.value).join``;
    event.target.setSelectionRange(i, j);
    this.back = false;
  }
}
