import { Controller } from "stimulus";
import axios from "axios";
import _ from "lodash";

export default class extends Controller {
  static targets = [
    "form",
    "searchForm",
    "results",
    "chosen",
    "npi",
    "npiInput",
    "firstNameQuery",
    "lastNameQuery",
    "name",
    "nameInput",
    "addressLine1",
    "addressLine2",
    "stateInput",
    "stateQuery",
    "phone",
    "phoneInput",
  ]

  apiURL = "/doctors"

  debouncedSearch = _.debounce(this.search, 250);

  handleSearch() {
    this.debouncedSearch();
  }

  search() {
    const firstName = this.firstNameQueryTarget.value;
    const lastName = this.lastNameQueryTarget.value;
    const state = this.stateQueryTarget.value;

    const searchQuery = new URLSearchParams();

    if (firstName.trim() != "") { searchQuery.append("first_name", firstName); }
    if (lastName.trim() != "") { searchQuery.append("last_name", lastName); }
    if (state.trim() != "") { searchQuery.append("state", state); }

    if (searchQuery.toString != "") {
      const apiQuery = `${this.apiURL}?${searchQuery.toString()}`;

      axios.get(apiQuery).
        then((response) => {
          if (response.status == 200 && !this.doctorChosen) {
            this.resultsTarget.innerHTML = response.data;
            this.showSearchForm();
            this.showResults();
          }
        });
    } else {
      this.clearSearchResults();
    }
  }

  get doctorChosen() {
    return !this.chosenTarget.classList.contains("hidden");
  }

  clearSearchResults() {
    hideResults();
  }

  chooseDoctor(event) {
    event.preventDefault();

    const eventData = event.target.dataset

    this.hideResults();
    this.hideSearchForm();

    this.showChosen(eventData);
  }

  manuallyEnterDoctor(event) {
    event.preventDefault();

    this.hideSearchForm();
    this.hideResults();
    this.clearChosen();

    this.showEntryForm();
  }

  switchToSearch(event) {
    event.preventDefault();

    this.firstNameQueryTarget.value = "";
    this.lastNameQueryTarget.value = "";
    this.stateQueryTarget.value = "";

    this.hideEntryForm();

    this.showSearchForm();
  }

  clearDoctor(event) {
    event.preventDefault();

    this.firstNameQueryTarget.value = "";
    this.lastNameQueryTarget.value = "";

    this.clearChosen();

    this.showSearchForm();
  }

  showSearchForm() {
    this.searchFormTarget.classList.remove("hidden");
  }

  hideSearchForm() {
    this.searchFormTarget.classList.add("hidden");
  }

  showResults() {
    this.resultsTarget.classList.remove("hidden");
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden");
  }

  showEntryForm() {
    this.formTarget.classList.remove("hidden");
  }

  hideEntryForm() {
    this.formTarget.classList.add("hidden");
  }

  showChosen(data) {
    this.npiTarget.textContent = data.npi;
    this.npiInputTarget.value = data.npi;
    this.nameTarget.textContent = data.name;
    this.nameInputTarget.value = data.name;
    this.addressLine1Target.textContent = data.addressLine1;
    this.addressLine2Target.textContent = data.addressLine2;
    this.stateInputTarget.value = data.state;
    this.phoneTarget.textContent = data.phoneNumber;
    this.phoneInputTarget.value = data.phoneNumber;

    this.chosenTarget.classList.remove("hidden");
  }

  clearChosen() {
    this.npiTarget.textContent = "";
    this.npiInputTarget.value = "";
    this.nameTarget.textContent = "";
    this.nameInputTarget.value = "";
    this.addressLine1Target.textContent = "";
    this.addressLine2Target.textContent = "";
    this.stateInputTarget.value = "";
    this.phoneTarget.textContent = "";
    this.phoneInputTarget.value = "";

    this.chosenTarget.classList.add("hidden");
  }
}
