import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "buttonLabel", "warningMinimal", "button" ]

  open() {
    this.element.classList.add("warning--open");
  }

  close() {
    this.element.classList.remove("warning--open");
  }

  toggleShow() {
    if (window.pageYOffset > 3700) {
      this.warningMinimalTarget.setAttribute("style", "height: 0; bottom: -12rem;");
    }

    else {
      this.warningMinimalTarget.setAttribute("style", "height: 141px; bottom: 0;");
    }
  }
}
